import React, { useState } from 'react';
import {
  AutocompleteController,
  FormFieldContainer,
  FormFieldContainerVariant,
  TextFieldController,
  formFields,
} from 'modules/onboarding/v3-streamlined-onboarding/pages/components/forms';
import {
  AddressFormSection,
  AddressFormSectionVariant,
} from 'modules/onboarding/v3-streamlined-onboarding/pages/components/forms/sections/address/address-form-section';
import { displayFormattedDateOfBirth } from 'modules/onboarding/v3-streamlined-onboarding/pages/components/forms/utils';
import { Person } from 'modules/onboarding/v3-streamlined-onboarding/types/person';
import { PersonVariant } from 'modules/onboarding/v3-streamlined-onboarding/enums';

const unitedStatesCountryValue = 'USA';

interface IPersonFormSection {
  person?: Person;

  control: any;

  unregister: any;

  variant: PersonVariant;
}

export const PersonFormSection: React.FC<IPersonFormSection> = ({
  person,
  control,
  unregister,
  variant,
}) => {
  // use the set citizenshipCountry and residencyCountry
  // or default to US if not set
  const [isCitizenUS, setIsCitizenUS] = useState(
    person?.citizenshipCountry === unitedStatesCountryValue ||
      !person?.citizenshipCountry,
  );
  const [isResidentUS, setIsResidentUS] = useState(
    person?.residencyCountry === unitedStatesCountryValue ||
      !person?.residencyCountry,
  );

  const isCitizenshipCountryUS = (selected: any) => {
    setIsCitizenUS(selected.target.value === unitedStatesCountryValue);

    unregister('nationalIdentifier', { keepTouched: true });

    return selected;
  };

  const isResidencyCountryUS = (selected: any) => {
    setIsResidentUS(selected.target.value === unitedStatesCountryValue);

    unregister(['state', 'zipCode'], { keepTouched: true });

    return selected;
  };

  return (
    <>
      <FormFieldContainer>
        <FormFieldContainer variant={FormFieldContainerVariant.Small}>
          <TextFieldController
            {...formFields.firstName}
            control={control}
            defaultValue={person?.firstName}
          />
        </FormFieldContainer>

        <TextFieldController
          {...formFields.lastName}
          control={control}
          defaultValue={person?.lastName}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <TextFieldController
          {...formFields.jobTitle}
          control={control}
          defaultValue={person?.jobTitle}
        />
      </FormFieldContainer>

      {variant === PersonVariant.Owner && (
        <FormFieldContainer>
          <TextFieldController
            {...formFields.ownershipPercentage}
            control={control}
            defaultValue={person?.ownershipPercentage}
            gridProps={{ xs: 4 }}
          />
        </FormFieldContainer>
      )}

      <FormFieldContainer>
        <TextFieldController
          {...formFields.phoneNumber}
          control={control}
          defaultValue={person?.phoneNumber}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <TextFieldController
          {...formFields.email}
          control={control}
          defaultValue={person?.email}
          disabled={person?.isPersonOnboarding && !!person?.email}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <AutocompleteController
          {...formFields.citizenshipCountry}
          control={control}
          defaultValue={person?.citizenshipCountry || unitedStatesCountryValue}
          onAfterChange={isCitizenshipCountryUS}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <TextFieldController
          {...formFields.dateOfBirth}
          control={control}
          defaultValue={
            person?.dateOfBirth
              ? displayFormattedDateOfBirth(person?.dateOfBirth)
              : ''
          }
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <TextFieldController
          {...(isCitizenUS
            ? formFields.USANationalIdentifier
            : formFields.nonUSANationalIdentifier)}
          control={control}
          defaultValue={person?.nationalIdentifier}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <AutocompleteController
          {...formFields.residencyCountry}
          control={control}
          defaultValue={person?.residencyCountry || unitedStatesCountryValue}
          onAfterChange={isResidencyCountryUS}
        />
      </FormFieldContainer>

      <AddressFormSection
        address={person?.addresses?.[0]}
        control={control}
        isUS={isResidentUS}
        variant={AddressFormSectionVariant.Residential}
      />

      {variant === PersonVariant.AuthorizedSigner && (
        <>
          <FormFieldContainer>
            <AutocompleteController
              {...formFields.governmentIdType}
              control={control}
              defaultValue={person?.governmentIdType}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <TextFieldController
              {...formFields.governmentIdNumber}
              control={control}
              defaultValue={person?.governmentIdNumber}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <TextFieldController
              {...formFields.governmentIdIssuingStateOrCountry}
              control={control}
              defaultValue={person?.governmentIdIssuingStateOrCountry}
            />
          </FormFieldContainer>
        </>
      )}
    </>
  );
};
